import React, { useEffect, useState } from 'react'
import {
    FaBagShopping,
    FaShop,
    FaMagnifyingGlass,
    FaPeopleGroup,
    FaLinesLeaning,
    FaRegFileLines

} from "react-icons/fa6";
import Window from '../Window';
import "./styles/merchants.css";
import BuildCustomers from '../builders/BuildCustomers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../shared/assets/stores/store';
import { GetAllUsers, AllMerchantTransactions } from '../../shared/assets/slices/adminSlice';
import NumberFormatter from '../../customer/components/NumberFormatter';
import ReactPaginate from 'react-paginate';

type Props = {}

interface MerchantProps {
  id: number;
  phone_number: string;
  email: string;
  first_name: string;
  last_name: string;
  user_type: string;
  address: string;
  username: string;
  last_login_ip: string;
  last_login_user_agent: string;
  is_locked: boolean;
  is_activated: boolean;
  avatar: string;
  country: string;
  created_at: string,
  profile_picture: string,
  business_name: string,
  business_certificate: string,
  business_type: string,
  rc_number: string,
  rc_certificate: string,
  tax_id: string,
  tax_certificate: string,
  id_type: string,
  upload_id: string,
  bvn: string,
  enter_bvn_number: string,
  bank_name: string,
  account_number: string,
  have_pin: boolean,
  compliant: boolean,
}

interface transactionProps {
    id: number;
    amount: number;
    fee: number;
    balance_before: number;
    balance_after: number;
    order: string;
    reference: string;
    note: string;
    gateway: string;
    transaction_type: string;
    payment_type: string;
    status: string;
    date: string;
    user?: {
        id: number;
        first_name: string;
        last_name: string;
        username: string;
        country: string;
        phone_number: string;
        user_type: string;
        address: string;
        email: string;
        is_activated: boolean;
        avatar: string,
        profile_picture: string;
        business_name: string;
        compliant: boolean;
    };
}

const MerchantsView = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [customers, setCustomers] = useState<MerchantProps[]>([]);
    const [totalCustomers, setTotalCustomers] = useState<number>(0);
    const [totalCustomerTransactions, setTotalCustomerTransactions] = useState<number>(0);
    const admindata = useSelector((state: RootState) => state.admin);
    const { access_token, user } = admindata.getAdmin.data;
    const customersPerPage = 5;


    useEffect(() => {
        const fetchTransactions = async () => {
            const response = await dispatch(AllMerchantTransactions());
            console.log("transactions: ", response.payload.results);
            const customerTransactions = response.payload.results.filter((transactions: any) => transactions?.user?.user_type === "Customer");
            setTotalCustomerTransactions(Number(customerTransactions.length));
        };
        fetchTransactions();
    }, [dispatch]);

    useEffect(() => {
        const fetchMerchants = async () => {
            const response = await dispatch(GetAllUsers(access_token));
            const customerData = response.payload
                .filter((user: any) => user.user_type === "Customer")
                .sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setCustomers(customerData);
            setTotalCustomers(Number(customerData.length));
        }
        fetchMerchants();
    }, [dispatch, access_token]);

    // Handle pagination page change
    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };

    // Handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0); // Reset to first page on new search
    };

    // Filter merchants based on search term
    const filteredCustomers = customers.filter(customer =>
       (customer.business_name && customer.business_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        customer.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (customer.country && customer.country.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Pagination calculations for filtered merchants
    const pageCount = Math.ceil(filteredCustomers.length / customersPerPage);
    const offset = currentPage * customersPerPage;
    const currentCustomers = filteredCustomers.slice(offset, offset + customersPerPage);



    return (
        <div className='merch_sup_cont'>
            <h2 className='merch_title1'>Customers</h2>
            <div className='merch_overview'>
                <div className='merch_overview_sec'>
                    <FaBagShopping size={30} color='#EA580C' className='merch_overview_icn' />
                    <div>
                        <p className='merch_overview_desc'>Total Customers</p>
                        <p className='merch_overview_amt'><NumberFormatter amount={totalCustomers}/></p>
                    </div>
                </div>
                <div className='merch_overview_sec'>
                    <FaLinesLeaning size={30} color='#9333EA' className='merch_overview_icn' />
                    <div>
                        <p className='merch_overview_desc'>Total Orders</p>
                        <p className='merch_overview_amt'><NumberFormatter amount={totalCustomerTransactions}/></p>
                    </div>
                </div>
            </div>
            <div className='merch_merch_cont'>
                <div className='merch_search_cont'>
                    <div className='merch_search_input_wrpr'>
                        <input type="search" className='merch_search_box' />
                        <FaMagnifyingGlass size={20} color='#eee' className='merch_search_icn' />
                    </div>
                </div>
                <div className='merch_merch_hd'>
                    <p className='merch_merch_hd_txt'>FirstName</p>
                    <p className='merch_merch_hd_txt'>LastName</p>
                    <p className='merch_merch_hd_txt'>Country</p>
                    <p className='merch_merch_hd_txt'>Action</p>
                </div>
                <div>
                    {/* {customers.map((customer) => BuildCustomers(customer))} */}
                    {currentCustomers.map((customer) => (
                        <BuildCustomers key={customer.id} {...customer} />
                    ))}
                </div>
            </div>
            {pageCount > 1 && (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                />
            )}
        </div>
    )
}
const Merchants = () => {
    const content = {
        currentView: <MerchantsView />,
        activeId: 3
    }
    return (
        <Window currentView={content.currentView} activeId={content.activeId} />
    )
}
export default Merchants