import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../shared/assets/images/amaps.png';
import { Carousel, Navbar, Nav, Container, Row, Col, Button, Card } from 'react-bootstrap';
import slider1 from '../../shared/assets/images/pexels9.png';
import slider2 from '../../shared/assets/images/pexels17.png';
import slider3 from '../../shared/assets/images/guy-with-earpiece.png';
import skincare from '../../shared/assets/images/pexels1.png';
import comfort from '../../shared/assets/images/pexels10.png';
import essentials from '../../shared/assets/images/pexels11.png';
import ojapay from '../../shared/assets/images/ojapay.png';
import imageone from '../../shared/assets/images/pexels6.png';
import imagetwo from '../../shared/assets/images/pexels19.png';
import imagethree from '../../shared/assets/images/pexels111.png';
import LanguageSwitcher from '../../LanguageSwitcher';
import LandingNavbar from '../components/LandingNavbar';
import LandingHero from '../components/LandingHero';
import About from '../components/About';
import FeaturesAndBenefits from '../components/FeaturesAndBenefits';
import HowItWorks from '../components/HowItWorks';
import CallToAction from '../components/CallToAction';
import SecurityCompliance from '../components/SecurityCompliance';
import Testimonials from '../components/Testimonials';
import PartnersIntegrations from '../components/PartnersIntegrations';
import Footer from '../components/Footer';
import '../assets/css/Landing.css';

const Landing: React.FC = () => {
  const { t } = useTranslation('customer_landing');
  console.log("translation from customer landing: ", t)

  return (
    <>
          <LandingNavbar/>
          <LandingHero/>  
          <About/>
          <FeaturesAndBenefits/>
          <HowItWorks/>
          <CallToAction/>
          <SecurityCompliance/>
          <Testimonials/>
          <PartnersIntegrations/>
          <Footer/>
    </>
  );
};

export default Landing;

